<template>
  <div>
    <v-card color="cyan lighten-5" rounded="0" flat>
      <v-card-title class="text-center justify-center pl-7">
        <h2 class="text-h4 titulo-card basil--text text-center">
          {{ user.Name }} {{ user.LastName }}
        </h2>
      </v-card-title>
    </v-card>
    <v-container>
      <v-alert
        text
        prominent
        type="error"
        icon="mdi-cloud-alert"
        v-if="alertError != ''"
      >
        {{ alertError }}
      </v-alert>
      <v-alert
        text
        prominent
        type="success"
        icon="mdi-cloud-alert"
        v-if="alertSuccess != ''"
      >
        {{ alertSuccess }}
      </v-alert>
      <template> </template>
      <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Wait please
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFisrtTime"
        transition="dialog-top-transition"
        width="700"
      >
        <v-card>
          <v-card-text>
            <div class="text-h3 pa-8">Welcome to Leelee Teacher Edition</div>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <div class="text-h5 pa-8">
                  First Select your Class from Clever
                </div>
              </v-col>
              <v-col cols="4 my-auto py-auto">
                <v-btn rounded color="primary" @click="CloseFisrtTime"
                  >Continue</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogImportClever"
        transition="dialog-top-transition"
        width="900"
      >
        <v-card>
          <v-toolbar flat color="teal lighten-3">
            <v-toolbar-title>Choose your class</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <template v-for="(clase, index) in clasesClever">
                <v-col cols="6" md="4" class="my-2">
                  <v-card tile>
                    <v-card-title>{{ clase.name }}</v-card-title>
                    <v-card-title>Grade : {{ clase.grado }}</v-card-title>
                    <v-card-subtitle
                      >Students : {{ clase.length }}</v-card-subtitle
                    >
                    <v-btn
                      color="teal lighten-3"
                      width="100%"
                      @click="AddClassClever(clase)"
                      :disabled="overlay"
                      :loading="overlay"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                      Import
                    </v-btn>
                  </v-card>
                </v-col>
              </template>
              <v-row v-if="clasesClever.length == 0" class="my-3">
                Does not have classes available
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col
          class="col-sm-6 col-md-4 col-xl-3"
          v-for="(classroom, index) in classrooms"
          :key="index"
          style="position: relative"
        >
          <div style="position: absolute; z-index: 1; right: 28px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="rgba(17,97,94,1)"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="pa-1"
                  @click="editClassroom(classroom)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </div>
          <v-card
            min-width="200px"
            class="ma-4"
            :to="'/schoolTeacher/classroom/' + `${classroom.id}`"
            color="blue-grey lighten-5"
          >
            <v-row>
              <v-col cols="12" class="pb-0">
                <p
                  class="
                    my-0
                    py-0
                    pr-0
                    font-weight-bold
                    text-center text-h6
                    altura-titulo
                  "
                >
                  {{ classroom.name }}
                </p>
              </v-col>

              <v-col md="5" sm="4" class="my-auto text-center">
                <v-avatar size="50" tile class="ml-md-3 ml-lg-4 ml-xl-5">
                  <v-img src="../../../assets/admin/aula.png"></v-img>
                </v-avatar>
              </v-col>
              <v-col md="7" sm="8" class="pl-1">
                <v-card-text class="subtitle-1 py-0">
                  <div>{{ classroom.numberStudents }} Students</div>
                  <div>{{ classroom.numberGroup }} Groups</div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col class="col-sm-6 col-md-4 col-xl-3">
          <v-card
            @click="OpenDialogNewClass"
            min-width="200px"
            min-height="152px"
            class="mx-4 mt-1"
            color="blue-grey lighten-5"
          >
            <v-col class="text-center">
              <v-icon size="50" class="ma-2">mdi-plus-box</v-icon>
              <p class="text-h5">Add Classroom</p>
            </v-col>
          </v-card>
          <div
            class="message-first"
            v-if="user.FirstTime && classrooms.length == 0"
          >
            <div class="border-message">
              <p>Let's start by creating your first class</p>
              <p>click on Add Classroom</p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogClassroom" max-width="1000px">
        <v-card>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="save"
            lazy-validation
          >
            <v-toolbar flat color="cyan lighten-3" dense>
              <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="blue-grey lighten-1"
                @click="dialogClassroom = false"
              >
                <v-icon left> mdi-window-close </v-icon>
                Close
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="classroom.name"
                      :rules="nameRules"
                      label="Class Name"
                      outlined
                      clearable
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="classroom.grade"
                      outlined
                      :items="grades"
                      label="Grade"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                small
                text
                color="error"
                v-if="editedUID != -1"
                @click="deleteClassroom"
                ><v-icon>mdi-delete</v-icon>Delete Class</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="blue-grey lighten-2" outlined @click="close"
                >Cancel</v-btn
              >
              <v-btn large color="success" outlined type="submit"
                >Save <v-icon>mdi-check</v-icon></v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-toolbar flat color="cyan lighten-4" dense>
            <v-toolbar-title>Are you sure you want to delete?</v-toolbar-title>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey lighten-2" large @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="error" large @click="deleteClassroomConfirm"
              >OK <v-icon>mdi-delete</v-icon></v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
        <v-card color="rgba(0, 143, 135, 141)" dark>
          <v-card-text>
            Wait please
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { db, functions } from "../../../plugins/firebase";
export default {
  data() {
    return {
      alertError: "",
      alertSuccess: "",
      tableHeader: [
        { text: "Name", value: "name" },
        { text: "Number of Students", value: "numberStudents" },
        { text: "Number of Groups", value: "numberGroup" },
        { text: "Students", value: "students" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      user: {},
      classrooms: [],
      classroom: {
        name: "",
        grade: "",
      },
      clearClassroom: {
        name: "",
        grade: "",
      },
      grades: [],
      nameRules: [(value) => !!value || "Required."],
      editedUID: -1,
      dialogClassroom: false,
      dialogDelete: false,
      dialogLoad: false,
      dialogFisrtTime: false,
      dialogImportClever: false,
      valid: true,
      isClever: false,
      clasesClever: [],
      overlay: false,
      dialogLoading: false,
      toolTipNewClass: false,
    };
  },
  created() {
    this.GetDataUser();

  },
  methods: {
    async GetDataUser() {
      let uidLogin = this.$store.state.user.uid;
      await db
        .collection("SchoolTeacher")
        .doc(uidLogin)
        .onSnapshot(
          (doc) => {
            this.user = doc.data();
            this.VerifyTokenClever();

            //this.dialogFisrtTime = doc.data().firstTime;
            let ListClassrooms = doc.data().Classrooms;
            let newClassrooms = [];
            if (Object.keys(ListClassrooms).length !== 0) {
              let ids = Object.keys(ListClassrooms);
              ids.forEach((element) => {
                newClassrooms.push({
                  id: element,
                  name: ListClassrooms[element].Name,
                  numberStudents: ListClassrooms[element].NumberStudents,
                  numberGroup: ListClassrooms[element].NumberGroup,
                  idClassClever: ListClassrooms[element].IdClassClever,
                });
              });
            }
            //newClassrooms.sort(function(a, b){return a.id - b.id});
            newClassrooms.sort((a, b) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            );
            //console.log(newClassrooms)
            this.classrooms = newClassrooms;
            //console.log(this.user.reference.path)
            this.user.ListGradesReference.get().then((doc) => {
              this.grades = doc.data().GradeList;
            });
          },
          (error) => {
            console.log(error);
          }
        );
    },

    CloseFisrtTime() {
      // db.collection("SchoolTeacher").doc(this.user.UserUID).update({
      //   firstTime: false
      // }).then(()=>{
      //   this.dialogFisrtTime =false;
      // });
      this.ImportClassClever();
    },
    async GetDataClassroom() {
      let getData = await db
        .collection("SchoolTeacher")
        .doc(this.user.UserUID)
        .collection("Classrooms")
        .doc(this.editedUID)
        .get();
      return getData.data();
    },
    OpenDialogNewClass() {
      //console.log('asdasd')
      this.dialogClassroom = true;
    },
    editClassroom(data) {
      console.log(data);
      this.editedUID = data.id;
      this.GetDataClassroom().then((getUser) => {
        console.log(getUser);
        this.classroom.name = getUser.Name;
        this.classroom.grade = getUser.Grade;
        this.classroom.numberGroup = data.numberGroup;
        this.classroom.numberStudents = data.numberStudents;
      });
      // this.classroom = Object.assign({}, data)
      this.dialogClassroom = true;
    },
    deleteClassroom() {
      // this.classroom = Object.assign({}, data)
      this.dialogDelete = true;
    },
    deleteClassroomConfirm() {
      this.dialogLoading = true;
      this.deleteData(this.editedUID).then((answer) => {
        console.log(answer);
        this.dialogLoading = false;
        let me = this;
        if (answer.result) {
          this.alertSuccess = "deleted successfully";
          setTimeout(function () {
            me.alertSuccess = "";
          }, 3000);
        } else {
          //console.log('prueba')
          this.alertError = answer.message;
          setTimeout(function () {
            me.alertError = "";
          }, 3000);
        }
        this.closeDelete();
        this.close();
      });
    },
    close() {
      this.dialogClassroom = false;
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.classroom = Object.assign({}, this.clearClassroom);
        this.editedUID = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.classroom = Object.assign({}, this.clearClassroom)
      //   this.editedUID = -1
      // })
    },
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData(this.classroom).then((response) => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "update successfully ";
            let principal = this;
            setTimeout(function () {
              principal.alertSuccess = "";
            }, 3000);
          } else {
            let principal = this;
            if (
              Object.prototype.hasOwnProperty.call(response.message, "code")
            ) {
              switch (response.message.code) {
                case "not-found":
                  this.alertError = "no se encontro el documento";
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () {
              principal.alertError = "";
            }, 3000);
          }
          this.dialogLoad = false;
        });
      } else {
        this.dialogLoad = true;
        this.createClassroom(this.classroom).then((response) => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () {
              principal.alertError = "";
            }, 3000);
          } else {
            this.alertSuccess = "created successfully";
            let principal = this;
            setTimeout(function () {
              principal.alertSuccess = "";
            }, 3000);
          }
          this.dialogLoad = false;
        });
      }
      this.close();
      this.$refs.form.resetValidation();
    },
    generateRandomString(num) {
      const characters = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
      let result1 = "";
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result1 += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result1;
    },
    async createClassroom(classroom) {
      console.log(classroom);
      let slug = classroom.name
        .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, " ")
        .toLowerCase();
      slug = slug.replace(/^\s+|\s+$/gm, "");
      slug = slug.replace(/\s+/g, "-");
      let answer = { result: "", message: "" };
      try {
        let code = this.generateRandomString(5);
        code = code.trim();
        console.log(code);
        let existe = true;
        let intentos = 0;
        while (existe) {
          let demo = await db.collection("CodeAccessStudents").doc(code).get();
          //console.log(demo.exists)
          if (!demo.exists) {
            existe = false;
          }
          intentos++;
          code = this.generateRandomString(5);
          code = code.trim();
          if (intentos > 4) {
            break;
          }
        }
        let map = {
          Name: classroom.name,
          NumberStudents: 0,
          NumberGroup: 0,
        };
        var batch = db.batch();
        batch.set(db.collection("CodeAccessStudents").doc(code), {
          TeacherId: this.user.UserUID,
          Classroom: slug,
          Reference: this.user.Reference,
          LicenseExpirationDate: this.user.LicenseExpirationDate,
          School: this.user.School,
        });
        batch.set(
          db
            .collection("SchoolTeacher")
            .doc(this.user.UserUID)
            .collection("Classrooms")
            .doc(slug),
          {
            Name: classroom.name,
            Grade: classroom.grade,
            Groups: {},
            MainGroup: {},
            CodeAccess: code,
          }
        );
        batch.update(db.collection("SchoolTeacher").doc(this.user.UserUID), {
          [`Classrooms.${[slug]}`]: map,
        });
        await batch.commit();
        // await db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(slug).set({ name: classroom.name, grade: classroom.grade, Groups: {}, MainGroup: {}, code:'' }).then((res) => {
        //   let map = {
        //     name: classroom.name,
        //     numberStudents: 0,
        //     numberGroup: 0
        //   }
        //   db.collection('SchoolTeacher').doc(this.user.UserUID).update({ [`Classrooms.${[slug]}`]: map })
        // });
      } catch (error) {
        console.log(error);
        answer.result = false;
        answer.message = error;
      }
      return answer;
    },
    async updateData(classroom) {
      console.log(classroom);
      let idAux = this.editedUID;
      let answer = { result: "", message: "" };
      try {
        await db
          .collection("SchoolTeacher")
          .doc(this.user.UserUID)
          .collection("Classrooms")
          .doc(idAux)
          .update({ Name: classroom.name, Grade: classroom.grade });
        await db
          .collection("SchoolTeacher")
          .doc(this.user.UserUID)
          .update({ [`Classrooms.${[[idAux]]}.Name`]: classroom.name });
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log("entro al log general");
      }
      return answer;
    },
    async deleteData(id) {
      let students = [];
      const resultado = this.classrooms.find((idClass) => idClass.id === id);
      if (resultado.idClassClever != undefined) {
        students = await this.GetStudentsClever(resultado.idClassClever);
        console.log(students);
        students = students.data.students;
      }
      let answer = { result: "", message: "" };
      try {
        if (this.classroom.numberStudents !== 0) {
          console.log(this.classroom.numberStudents);
          throw new Error("need delete students of this class");
        }
        const deleteClassroom = functions.httpsCallable("deleteClassroom");
        await deleteClassroom({
          idgroup: id,
          idTeacher: this.user.UserUID,
          students: students,
        }).then(() => {
          answer.result = true;
        });
      } catch (error) {
        console.log(error.message);
        answer.message = error.message;
        answer.result = false;
      }
      return answer;
    },
    VerifyTokenClever() {
      console.log(this.user);
      db.collection("TempTokensClever")
        .doc(this.user.UserUID)
        .get()
        .then((doc) => {
          console.log(doc.data());
          if (!doc.exists) return;
          let dia = new Date(doc.data().date);
          let hoy = new Date();
          dia.setHours(0, 0, 0, 0);
          hoy.setHours(0, 0, 0, 0);
          if (dia.getTime() == hoy.getTime()) {
            this.tempTokenClever = doc.data().token;
            this.isClever = true;
          }
          // if (this.user.firstTime) {
          //   this.dialogFisrtTime = true;
          // }
        });
    },
    async GetStudentsClever(idClassClever) {
      let clases = [];
      const url = "https://api.clever.com/v3.0/sections/" + idClassClever;
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.tempTokenClever,
        },
      };

      // await fetch(url, options)
      //   .then(res => res.json())
      //   .then(json => {
      //     console.log(json)
      //   })
      //   .catch(err => console.error('error:' + err));
      let res = await fetch(url, options);
      clases = await res.json();
      return clases;
    },
    ImportClassClever() {
      //console.log(this.classrooms)

      let clases = [];
      const url =
        "https://api.clever.com/v3.0/users/" + this.user.UserUID + "/sections";
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.tempTokenClever,
        },
      };

      fetch(url, options)
        .then((res) => res.json())
        .then((json) => {
          //console.log(json)
          let prueba = json.data;
          this.dialogImportClever = true;
          prueba.forEach((element) => {
            const resultado = this.classrooms.find(
              (idClass) => idClass.idClassClever === element.data.id
            );
            //console.log(resultado)
            if (resultado == undefined) {
              clases.push({
                grado: element.data.grade,
                name: element.data.name,
                length: element.data.students.length,
                students: element.data.students,
                idClass: element.data.id,
              });
            }
          });
          this.clasesClever = clases;
        })
        .catch((err) => console.error("error:" + err));
    },
    async AddClassClever(clase) {
      this.overlay = true;
      // console.log(clase)
      // console.log(this.user)
      var batch = db.batch();
      let slug = clase.name
        .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, " ")
        .toLowerCase();
      slug = slug.replace(/^\s+|\s+$/gm, "");
      slug = slug.replace(/\s+/g, "-");
      batch.set(
        db
          .collection("SchoolTeacher")
          .doc(this.user.UserUID)
          .collection("Classrooms")
          .doc(slug),
        {
          Name: clase.name,
          Grade: clase.grado,
          Groups: {},
          MainGroup: {},
        }
      );
      batch.update(db.collection("SchoolTeacher").doc(this.user.UserUID), {
        [`Classrooms.${[slug]}`]: {
          Name: clase.name,
          NumberStudents: 0,
          NumberGroup: 0,
          IdClassClever: clase.idClass,
        },
      });
      clase.students.forEach((element) => {
        batch.set(db.collection("StudentsUserClever").doc(element), {
          Reference: this.user.Reference,
          Teacher: this.user.UserUID,
          Classroom: slug,
          School: this.user.School,
          LicenseExpirationDate: this.user.LicenseExpirationDate,
          Create: true,
          Classroom: slug,
        });
      });
      await batch.commit();
      this.dialogImportClever = false;
      this.overlay = false;
    },
  },
  mounted() {},
  computed: {
    formTitle() {
      return this.editedUID === -1 ? "Create your Classroom" : "Edit Classroom";
    },
  },
  watch: {
    dialogClassroom(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogLoad(val) {
      if (!val) return;
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  },
};
</script>
<style scoped>
.altura-titulo {
  min-height: 60px;
}
.message-first {
  text-align: center;
  font-size: 20px;
  position: relative;
  margin-top: 0px;
  padding-top: 35px;
}
.border-message {
  background-color: #97c6e3;
  border-radius: 25px;
}
.border-message:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 30px solid #97c6e3;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  top: 6px;
  left: 45%;
}
</style>